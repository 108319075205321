/*
 * @Author: your name
 * @Date: 2021-06-22 15:50:18
 * @LastEditTime: 2021-06-29 13:18:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\view\index.js
 */
import React, { Component } from 'react'
// import { connect } from 'react-redux'
// import { DatePicker, List } from 'antd-mobile'
// import 'antd-mobile/dist/antd-mobile.css'
// import enUs from 'antd-mobile/lib/date-picker/locale/en_US'

class index extends Component {
  state = {}
  render () {
    return <div>首页</div>
  }
}

export default index
