/*
 * @Author: your name
 * @Date: 2021-06-29 13:45:37
 * @LastEditTime: 2021-07-06 19:52:20
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\view\report\charts\Bar.js
 */
import React, { Component } from 'react'
import * as echarts from 'echarts'
import { barOption } from './Option'
class Bar extends Component {
  lineOption = {}
  lineCharts = {}
  componentDidMount () {
    let { color, data, planData } = this.props
    this.setBarCharts({ color, data, planData })
  }
  setBarCharts = params => {
    this.lineOption = barOption
    this.lineOption.series[0].itemStyle.normal.color = new echarts.graphic.LinearGradient(
      1,
      0,
      0,
      1,
      [
        {
          offset: 1,
          color: `rgba(${params.color[0]}, 1)`
        },
        {
          offset: 0,
          color: `rgba(${params.color[0]}, 0.5)`
        }
      ]
    )
    this.lineOption.series[1].itemStyle.normal.color = `rgba(${params.color[1]}, 0.19)`
    this.lineOption.series[0].data = params.planData ? [params.data] : [0]
    this.lineOption.series[1].data = params.planData
      ? [params.planData / 10000]
      : [1]
    this.lineCharts = echarts.init(this.element)
    this.lineCharts.setOption(this.lineOption)
  }
  render () {
    let { data, planData } = this.props

    return (
      <div>
        <div
          ref={ele => (this.element = ele)}
          style={{ width: '72%', height: '30px', float: 'left' }}
        ></div>
        <div style={{ width: '25%', float: 'left' }}>
          {planData ? ((data / (planData / 10000)) * 100).toFixed(2) : '0'}%
        </div>
      </div>
    )
  }
}

export default Bar
