/*
 * @Author: your name
 * @Date: 2021-07-01 17:27:04
 * @LastEditTime: 2021-07-05 15:25:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\view\report\charts\CrossBar.js
 */
import React, { Component } from 'react'
import * as echarts from 'echarts'
import { crossBar } from './Option'
class CrossBar extends Component {
  lineOption = {}
  lineCharts = {}
  componentDidMount () {
    let { color, data, fdl } = this.props
    this.setBarCharts({ color, data, fdl })
  }
  setBarCharts = params => {
    let { data } = params

    this.lineOption = crossBar
    let numBer = []
    let barData = []
    if (data && data.length > 0) {
      data.forEach(res => {
        numBer.push(res.jzno)
        barData.push(res.fdl)
      })
    }
    this.lineOption.xAxis[0].data = numBer
    this.lineOption.series[0].data = barData
    this.lineCharts = echarts.init(this.element)
    this.lineCharts.setOption(this.lineOption)
  }
  render () {
    return (
      <div>
        <div
          ref={ele => (this.element = ele)}
          style={{ width: '100%', height: '220px' }}
        ></div>
      </div>
    )
  }
}

export default CrossBar
