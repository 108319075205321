/*
 * @Author: your name
 * @Date: 2021-06-29 10:39:12
 * @LastEditTime: 2021-07-08 13:23:57
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\view\report\charts\Line.js
 */
import React, { Component } from 'react'
import * as echarts from 'echarts'
import { lineOption } from './Option'
import moment from 'moment'
class Line extends Component {
  lineCharts = {}
  lineOption = {}
  componentDidMount () {
    let { color, data, type, unit, bgColor } = this.props
    if (type === 1) {
      this.setOneCharts({ color, data, unit, bgColor })
    } else if (type === 2) {
      this.setTwoCharts({ color, data, unit, bgColor })
    }
  }

  setOneCharts = params => {
    let { data } = params
    this.lineOption = lineOption
    this.lineOption.color = params.color
    let sor = {}
    let xData = []
    let yData = []
    let dataIf = 0

    if (data.history) {
      sor = data.history ? JSON.parse(data.history) : {}
    }
    for (var item in sor) {
      xData.push(moment(item).format('HH:mm'))
      dataIf = parseFloat(sor[item]) ? parseFloat(sor[item]).toFixed(2) : 0
      yData.push(dataIf)
    }

    // this.lineOption.legend.data = [data.name]
    this.lineOption.series[0] = {
      name: data.name,
      type: 'line',
      data: yData,
      markPoint: {
        data: [
          { type: 'max', name: '最大值', xAxis: 1, yAxis: -3.0 },
          {
            type: 'min',
            name: '最小值'
            // symbolOffset: ['1', '-10%'],
            // symbol: 'arrow'
            // coord: [0, 0]
          }
        ]
      },
      markLine: {
        data: [{ type: 'average', name: '平均值' }]
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: `rgba(${params.bgColor},0.8)`
          },
          {
            offset: 1,
            color: `rgba(${params.bgColor},0.1)`
          }
        ])
      }
    }
    this.lineOption.xAxis.data = xData
    this.lineOption.yAxis.name = params.unit
    this.lineCharts = echarts.init(this.element)
    this.lineCharts.setOption(this.lineOption)
  }

  setTwoCharts = params => {
    let { data } = params
    this.lineOption = lineOption
    this.lineOption.color = params.color
    let xData1 = []
    let yData1 = []
    let xData2 = []
    let yData2 = []
    let ftooName = ''
    let ftotName = ''

    if (data.length > 0) {
      // data.forEach(item => {
      //   sor.push()
      // })
      let ftoo = data[0].history ? JSON.parse(data[0].history) : ''
      let ftot = data[1].history ? JSON.parse(data[1].history) : ''
      ftooName = data[0].name
      ftotName = data[1].name

      for (var item1 in ftoo) {
        xData1.push(moment(item1).format('HH:mm'))
        let dataIf = parseFloat(ftoo[item1])
          ? parseFloat(ftoo[item1]).toFixed(2)
          : 0
        yData1.push(dataIf)
      }

      for (var item2 in ftot) {
        xData2.push(moment(item2).format('HH:mm'))
        let dataIf = parseFloat(ftot[item2])
          ? parseFloat(ftot[item2]).toFixed(2)
          : 0
        yData2.push(dataIf)
      }
    }
    this.lineOption.legend.data = [ftooName, ftotName]
    this.lineOption.series[0] = {
      name: ftooName,
      type: 'line',
      data: yData1,
      markPoint: {
        data: [
          { type: 'max', name: '最大值' },
          { type: 'min', name: '最小值' }
        ]
      },
      markLine: {
        data: [{ type: 'average', name: '平均值' }]
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: `rgba(${params.bgColor},0.8)`
          },
          {
            offset: 1,
            color: `rgba(${params.bgColor},0.1)`
          }
        ])
      }
    }
    this.lineOption.series[1] = {
      name: ftotName,
      type: 'line',
      data: yData2,
      markPoint: {
        data: [
          { type: 'max', name: '最大值' },
          { type: 'min', name: '最小值' }
        ]
      },
      markLine: {
        data: [{ type: 'average', name: '平均值' }]
      },
      areaStyle: {
        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: `rgba(${params.bgColor},0.8)`
          },
          {
            offset: 1,
            color: `rgba(${params.bgColor},0.1)`
          }
        ])
      }
    }

    this.lineOption.xAxis.data = xData1
    this.lineOption.yAxis.name = params.unit
    this.lineOption.legend.top = '85%'
    this.lineOption.grid = { top: '15%', left: '12%' }
    this.lineCharts = echarts.init(this.element)
    this.lineCharts.setOption(this.lineOption)
  }

  render () {
    return (
      <div
        ref={ele => (this.element = ele)}
        style={{ width: '100%', height: '280px' }}
      ></div>
    )
  }
}

export default Line
