/*
 * @Author: your name
 * @Date: 2021-06-28 10:51:09
 * @LastEditTime: 2021-06-28 11:08:52
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\Route\report\index.js
 */
import Report from '../../view/report/index'
const report = { Report }
export default report
