/*
 * @Author: your name
 * @Date: 2021-07-01 09:53:34
 * @LastEditTime: 2021-07-07 11:30:08
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\common\tool.js
 */

/**
 * url中得到 search
 * getQueryString('http://www.baidu.com?id=1111','id') // 1111
 */
const getQueryString = (url, name) => {
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  var r = url.substr(1).match(reg)
  if (r != null) {
    return unescape(r[2])
  }
  return null
}

/**
 * 是否是微信环境
 *
 * @returns
 */
const isWechat = () => {
  let ua = window.navigator.userAgent.toLowerCase()
  if (ua.match(/MicroMessenger/i)) {
    return true
  } else {
    return false
  }
}

//获取url的search参数
const getUrlParam = name => {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}

/**
 * 是否是微信环境
 *
 * @returns
 */
const haveCode = () => {
  let ua = window.location.search
  if (ua.match(/code/i)) {
    return true
  } else {
    return false
  }
}

export { getQueryString, isWechat, getUrlParam, haveCode }
