/*
 * @Author: your name
 * @Date: 2021-06-22 14:35:15
 * @LastEditTime: 2021-06-29 16:40:29
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\App.js
 */
import React from 'react'

import './App.css'
import Index from './view/layout/index.js'
import {
  HashRouter as Router,
  Route,
  // useHistory,
  Switch
} from 'react-router-dom'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
library.add(fas, fab)

function App () {
  return (
    <div className='App'>
      <Router>
        <Switch>
          <Route path='/' component={Index} />
        </Switch>
      </Router>
    </div>
  )
}

export default App
