/*
 * @Author: your name
 * @Date: 2021-06-22 17:40:04
 * @LastEditTime: 2021-07-06 19:14:12
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\Route\List.js
 */
import Index from '../../src/view/index'
// import Index from './report/index'
import Report from './report/index'

const routeList = { Index, ...Report }
export default routeList
