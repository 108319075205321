/*
 * @Author: your name
 * @Date: 2021-06-28 10:47:45
 * @LastEditTime: 2021-07-07 19:07:24
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\view\report\index.js
 */
import React, { Component } from 'react'
import {
  Flex,
  WhiteSpace,
  WingBlank,
  Card,
  Tabs,
  Toast,
  Result
} from 'antd-mobile'

import { getQueryString, isWechat } from '../../common/tool'
import IconClub from '../../common/IconClub'
import Line from './charts/Line'
import Bar from './charts/Bar'
import CrossBar from './charts/CrossBar'
import './index.less'

const myImg = src => <img src={src} className='spe am-icon am-icon-md' alt='' />

class index extends Component {
  ele = {}
  rjDom = {}
  qjDom = {}
  glDom = {}
  openId = ''
  queryType = ''
  code = ''
  state = {
    info: {},
    setTop: {},
    menu1: 'bgLight',
    menu2: 'bgWhite',
    menu3: 'bgWhite',
    showWarning: false,
    message: ''
  }

  componentDidMount () {
    if (isWechat()) {
      let openId = localStorage.getItem('openId')
      if (openId == '' || openId === null) {
        this.getCode()
      } else {
        this.openId = openId
        this.queryType = 'wechat'
        this.getInfo()
      }
    } else {
      this.getInfo()
    }
  }

  getInfo = () => {
    let id = this.openId || getQueryString(this.props.location.search, 'id')
    let queryType =
      this.queryType || getQueryString(this.props.location.search, 'queryType')

    fetch('https://wechat.gentory.cn/api/dailyReport', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        queryType,
        id
      })
    })
      .then(response => response.json())
      .then(data => {
        if (data.success) {
          this.setState({ info: data.datas })
          // this.scrollActive()
        } else {
          this.setState({ showWarning: true, message: data.message })
          Toast.error(data.message)
        }
      })
      .catch(e => {
        Toast.info('失败')
      })
  }

  scrollActive = e => {
    let rjHeight = document.getElementsByClassName('index-1')
    let qjHeight = document.getElementsByClassName('index-2')
    let glHeight = document.getElementsByClassName('index-3')
    let offHeight = 200

    window.onscroll = e => {
      var scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop >= this.ele.scrollHeight) {
        this.setState({
          setTop: {
            position: 'fixed',
            top: '0',
            width: '100%',
            zIndex: '1000'
          }
        })
      } else {
        this.setState({
          setTop: {}
        })
      }

      if (
        scrollTop >= rjHeight[0].offsetTop - offHeight &&
        scrollTop < qjHeight[0].offsetTop - offHeight
      ) {
        this.setState({
          menu1: 'bgLight',
          menu2: 'bgWhite',
          menu3: 'bgWhite'
        })
      } else if (
        scrollTop >= qjHeight[0].offsetTop - offHeight &&
        scrollTop < glHeight[0].offsetTop - offHeight
      ) {
        this.setState({
          menu1: 'bgWhite',
          menu2: 'bgLight',
          menu3: 'bgWhite'
        })
      } else if (scrollTop >= glHeight[0].offsetTop - offHeight) {
        this.setState({
          menu1: 'bgWhite',
          menu2: 'bgWhite',
          menu3: 'bgLight'
        })
      }
    }
  }

  setScrollTop = className => {
    let offHeight = className === 'index-1' ? 10 : 40
    let rjHeight = document.getElementsByClassName(className)
    window.scrollTo(0, rjHeight[0].offsetTop - offHeight)
  }

  getCode = () => {
    // 非静默授权，第一次有弹框
    // const code = getQueryString(this.props.location.search, 'code') // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
    // localStorage.setItem('code', code)

    var str = window.location.href
    var code = ''
    var reg = /code=((\w|-|\s)+)&/gi
    str.replace(reg, function () {
      code = arguments[1] // 截取路径中的code，如果没有就去微信授权，如果已经获取到了就直接传code给后台获取openId
    })

    if (!code) {
      window.location.href =
        'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa8bc1297461fb0f4&redirect_uri=' +
        encodeURIComponent('https://wechat.gentory.cn/#/report') +
        '&response_type=code&scope=snsapi_base&state=1#wechat_redirect'
    } else {
      this.fetchOpenId(code) //把code传给后台获取用户信息
    }
  }

  formatNumber(number) {
    if (!number) return ''
    const numberArr = number.split('.')
    if (numberArr.length  >= 2 ) {
      if (numberArr[1].length > 2) {
        return `${numberArr[0]}.${numberArr[1].substr(0,2)}`
      } else {
        return number
      }
    } else if (numberArr.length < 2) {
      return number
    }
  }

  fetchOpenId = code => {
    // 通过code获取 openId等用户信息，/api/user/wechat/login 为后台接口
    fetch('https://wechat.gentory.cn/api/wxopenid', {
      method: 'POST',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({ code })
    })
      .then(response => response.json())
      .then(res => {
        const { code, message, datas } = res
        this.setState({
          isLoading: false
        })
        if (code === 403) {
          window.location.href = 'https://wechat.gentory.cn/#/'
        } else {
          if (code === 9013) {
            // 显示数据
            const { wxOpenid } = datas
            this.openId = wxOpenid
            this.queryType = 'wechat'
            if (wxOpenid) localStorage.setItem('openId', wxOpenid)
            this.getInfo()
          } else {
            // 显示提示
            this.setState({ showWarning: true, message })
          }
        }
      })
  }

  render () {
    let { info } = this.state
    // const dailyReportGuoluQi1 = (info&&info?.dailyReportGuoluQi1)? 
    // this.formatNumber(dailyReportGuoluQi1) : ''
    // const dailyReportGuoluQi2 = (info&&info?.dailyReportGuoluQi2)? 
    // this.formatNumber(dailyReportGuoluQi2) : ''
    return (
      <div className={'report'}>
        <div
          style={{
            display: !this.state.showWarning ? 'none' : 'block'
          }}
        >
          <div
            style={{ fontSize: '20px', textAlign: 'center', padding: '12px' }}
          >
            <Result
              img={myImg(
                'https://gw.alipayobjects.com/zos/rmsportal/GIyMDJnuqmcqPLpHCSkj.svg'
              )}
              title={this.state.message}
              message={''}
            />

            {}
          </div>
        </div>
        <WingBlank
          size='lg'
          style={{ display: this.state.showWarning ? 'none' : 'block' }}
        >
          <div ref={e => (this.ele = e)}>
            <WhiteSpace size='lg' />
            <Flex>
              <Flex.Item style={{ fontSize: '12px', color: '#939393' }}>
                {info && info.dailyReportWeather}
              </Flex.Item>
              <Flex.Item
                style={{
                  fontSize: '12px',
                  textAlign: 'right',
                  color: '#939393'
                }}
              >
                {info && info.dailyReportDate}
              </Flex.Item>
            </Flex>
            <WhiteSpace size='lg' />
            <Flex>
              <Flex.Item>
                <Card>
                  <Card.Body>
                    <div>
                      <Flex>
                        <Flex.Item>
                          <IconClub
                            style={{ color: '#E28628' }}
                            icon={'fa-bolt'}
                          ></IconClub>
                        </Flex.Item>

                        <Flex.Item>
                          <div
                            style={{
                              textAlign: 'right',
                              fontSize: '12px',
                              color: '#CBCBCB'
                            }}
                          >
                            万kW·h
                          </div>
                        </Flex.Item>
                      </Flex>
                    </div>
                    <div style={{ textAlign: 'center' }} className={'title'}>
                      今日发电量
                    </div>
                    <div className={'fdl number'}>
                      {info && info.dailyReportZRFDL}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      {info && info.dailyReportZRFDL && info.plan ? (
                        <Bar
                          color={['243, 128, 12', '252, 215, 178']}
                          data={info.dailyReportZRFDL}
                          planData={info.plan.planDayTarget}
                        ></Bar>
                      ) : (
                        ''
                      )}
                    </div>
                  </Card.Body>
                  {info && info.plan ? (
                    <div  className={'zz'}>
                      今日计划: {(info.plan.planDayTarget/10000).toFixed(2)}
                    </div>):('')}
                </Card>
              </Flex.Item>

              <Flex.Item>
                <Card>
                  <Card.Body>
                    <div>
                      <Flex>
                        <Flex.Item>
                          <IconClub
                            style={{ color: '#EAD353' }}
                            icon={'fa-bolt'}
                          ></IconClub>
                        </Flex.Item>

                        <Flex.Item>
                          <div
                            style={{
                              textAlign: 'right',
                              fontSize: '12px',
                              color: '#CBCBCB'
                            }}
                          >
                            万kW·h
                          </div>
                        </Flex.Item>
                      </Flex>
                    </div>
                    <div style={{ textAlign: 'center' }} className={'title'}>
                      本月发电量
                    </div>
                    <div className={'fdl number'}>
                      {info && info.dailyReportDYFDL}
                    </div>
                    <div style={{ textAlign: 'center' }}>
                      {info && info.dailyReportDYFDL && info.plan ? (
                        <Bar
                          color={['248, 210, 10', '254, 230, 98']}
                          data={info.dailyReportDYFDL}
                          planData={info.plan.planMonthTarget}
                        ></Bar>
                      ) : (
                        ''
                      )}
                    </div>
                  </Card.Body>
                  {info && info.plan ? (
                    <div className={'zz'}>
                      本月计划: {(info.plan.planMonthTarget/10000).toFixed(2)}
                    </div>):('')}
                </Card>
              </Flex.Item>
            </Flex>
            <WhiteSpace size='lg' />
          </div>
          <Tabs
            tabs={[
              {
                title: '燃机数据'
              },
              {
                title: '汽轮机数据'
              },
              { title: '锅炉数据' }
            ]}
            initialPage={0}
            onChange={(tab, index) => {
              console.log('onChange', index, tab)
            }}
            onTabClick={(tab, index) => {
              console.log('onTabClick', index, tab)
            }}
            swipeable={true}
          >
            {/* <div style={this.state.setTop}>
              <div>
                <div
                  style={{
                    width: '100px',
                    padding: '8px',
                    float: 'left',
                    borderRadius: '8px',
                    marginRight: '4px'
                  }}
                  className={this.state.menu1}
                  onClick={() => this.setScrollTop('index-1')}
                >
                  燃机数据
                </div>
                <div
                  style={{
                    width: '100px',
                    padding: '8px',
                    float: 'left',
                    borderRadius: '8px',
                    marginRight: '4px'
                  }}
                  className={this.state.menu2}
                  onClick={() => this.setScrollTop('index-2')}
                >
                  汽轮机数据
                </div>
                <div
                  style={{
                    width: '100px',
                    padding: '8px',
                    float: 'left',
                    borderRadius: '8px',
                    marginRight: '4px'
                  }}
                  className={this.state.menu3}
                  onClick={() => this.setScrollTop('index-3')}
                >
                  锅炉数据
                </div>
                <div style={{ clear: 'both' }}></div>
              </div>
            </div> */}
            <div
              style={{ paddingTop: '12px' }}
              className={'index-1'}
              ref={e => (this.rjDom = e)}
            >
              {/* <div style={{ textAlign: 'left' }}>燃机数据</div> */}
              <Card>
                <Card.Body>
                  {info && info.dailyReportTableDetail2 ? (
                    <CrossBar
                      color={''}
                      data={
                        info && info.dailyReportTableDetail2
                          ? info.dailyReportTableDetail2
                          : []
                      }
                    ></CrossBar>
                  ) : (
                    ''
                  )}
                </Card.Body>
              </Card>
              <WhiteSpace size='lg' />
              <Card>
                <Card.Body>
                  <div
                    style={{
                      borderBottom: '1px solid #EFEFEF',
                      height: '40px',
                      fontSize: '12px',
                      fontWeight: '700',
                      color: '#3380D4'
                    }}
                  >
                    <div style={{ width: '20%', float: 'left' }}>机组</div>
                    <div style={{ width: '20%', float: 'left' }}>
                      发电量(万KW·h)
                    </div>
                    <div style={{ width: '20%', float: 'left' }}>运行小时</div>
                    <div style={{ width: '20%', float: 'left' }}>停机次数</div>
                    <div style={{ width: '20%', float: 'left' }}>报警次数</div>
                  </div>
                  {info && info.dailyReportTableDetail2
                    ? info.dailyReportTableDetail2.map((res, index) => {
                        return (
                          <div className='borderBottom' key={'0-1' + index}>
                            <div style={{ width: '20%', float: 'left' }}>
                              {res.jzno} #
                            </div>
                            <div style={{ width: '20%', float: 'left' }}>
                              {res.fdl}
                            </div>
                            <div style={{ width: '20%', float: 'left' }}>
                              {res.hour}
                            </div>
                            <div style={{ width: '20%', float: 'left' }}>
                              {res.stopCount}
                            </div>
                            <div style={{ width: '20%', float: 'left' }}>
                              {res.count}
                            </div>
                          </div>
                        )
                      })
                    : ''}
                </Card.Body>
              </Card>
            </div>

            <div
              style={{ paddingTop: '12px' }}
              className={'index-2'}
              ref={e => (this.qjDom = e)}
            >
              <Card>
                <Card.Body>
                  <Flex>
                    <Flex.Item>
                      <div className={'tabsContent'}>
                        <div className={'fa backGroundTitle'}>发电量</div>

                        <div className={'number'}>
                          {info &&
                            info.dailyReportQilunji &&
                            info.dailyReportQilunji.fdl}{' '}
                        </div>
                      </div>
                    </Flex.Item>
                    <Flex.Item>
                      <div className={'tabsContent'}>
                        <div className={'fa backGroundTitle'}>运行小时</div>
                        <div className={'number'}>
                          {info &&
                            info.dailyReportQilunji &&
                            info.dailyReportQilunji.hour}{' '}
                          h
                        </div>
                      </div>
                    </Flex.Item>
                    <Flex.Item>
                    <div
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        <div className={'fa backGroundTitle'}>蒸汽流量</div>

                        <div className={'number'}>
                        {info&&info.dailyReportQljQi?this.formatNumber(info.dailyReportQljQi):''}
                          t
                        </div>
                      </div>
                    </Flex.Item>
                  </Flex>
                </Card.Body>
              </Card>
              <WhiteSpace size='lg' />
              <Card>
                <Card.Body>
                  <div className={'fb backGroundTitle'}>汽轮机主蒸气温度</div>
                  <Card>
                    <Card.Body>
                      {info &&
                      info.dailyReportQilunji &&
                      info.dailyReportQilunji.datas[0] ? (
                        <Line
                          data={info.dailyReportQilunji.datas[0]}
                          color={['#4E72C6', '#8157B0', '#4297B3']}
                          type={1}
                          unit={'(°C)'}
                          bgColor={'78,114,198'}
                        ></Line>
                      ) : (
                        ''
                      )}
                    </Card.Body>
                  </Card>
                  <WhiteSpace size='lg' />
                  <div className={'fb backGroundTitle'}>汽轮机主蒸气压力</div>
                  <Card>
                    <Card.Body>
                      {info &&
                      info.dailyReportQilunji &&
                      info.dailyReportQilunji.datas[1] ? (
                        <Line
                          data={info.dailyReportQilunji.datas[1]}
                          color={['#8157B0', '#4E72C6', '#4297B3']}
                          type={1}
                          unit={'(kPa)'}
                          bgColor={'129,87,176'}
                        ></Line>
                      ) : (
                        ''
                      )}
                    </Card.Body>
                  </Card>
                  <WhiteSpace size='lg' />
                  <div className={'fb backGroundTitle'}>汽轮机真空值</div>
                  <Card>
                    <Card.Body>
                      {info &&
                      info.dailyReportQilunji &&
                      info.dailyReportQilunji.datas[2] ? (
                        <Line
                          data={info.dailyReportQilunji.datas[2]}
                          color={['#4297B3', '#4E72C6', '#8157B0']}
                          type={1}
                          unit={'(kPa)'}
                          bgColor={'66,151,179'}
                        ></Line>
                      ) : (
                        ''
                      )}
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Card>
            </div>
            <div
              style={{ paddingTop: '12px' }}
              className={'index-3'}
              ref={e => (this.glDom = e)}
            >
              <Card>
                <Card.Body>
                  <Flex>
                    <Flex.Item>
                      <div className={'tabsContent'}>
                        <div className={'fa backGroundTitle'}>1#锅炉蒸汽流量</div>

                        <div className={'number'}>
                          {info&&info.dailyReportGuoluQi1?this.formatNumber(info.dailyReportGuoluQi1):''}
                          t
                        </div>
                      </div>
                    </Flex.Item>
                    <Flex.Item>
                      <div
                        style={{
                          textAlign: 'center'
                        }}
                      >
                        <div className={'fa backGroundTitle'}>2#锅炉蒸汽流量</div>
                        <div className={'number'}>
                          {info&&info.dailyReportGuoluQi2?this.formatNumber(info.dailyReportGuoluQi2):''}
                          t
                        </div>
                      </div>
                    </Flex.Item>
                  </Flex>
                </Card.Body>
              </Card>
              <WhiteSpace size='lg' />
              <Card>
                <Card.Body>
                  <WhiteSpace size='lg' />
                  <div className={'fb backGroundTitle'}>锅炉汽包水位</div>
                  <Card>
                    <Card.Body>
                      {info && info.dailyReportGuoluData ? (
                        <Line
                          data={[
                            info.dailyReportGuoluData[0],
                            info.dailyReportGuoluData[1]
                          ]}
                          color={['#4E72C6', '#8157B0', '#4297B3']}
                          type={2}
                          unit={'(mm)'}
                          bgColor={'78,114,198'}
                        ></Line>
                      ) : (
                        ''
                      )}
                    </Card.Body>
                  </Card>
                  <WhiteSpace size='lg' />
                  <div className={'fb backGroundTitle'}>锅炉出口主蒸气温度</div>
                  <Card>
                    <Card.Body>
                      {info && info.dailyReportGuoluData ? (
                        <Line
                          data={[
                            info.dailyReportGuoluData[2],
                            info.dailyReportGuoluData[3]
                          ]}
                          color={['#4E72C6', '#8157B0', '#4297B3']}
                          type={2}
                          unit={'(°C)'}
                          bgColor={'78,114,198'}
                        ></Line>
                      ) : (
                        ''
                      )}
                    </Card.Body>
                  </Card>
                  <WhiteSpace size='lg' />
                  <div className={'fb backGroundTitle'}>锅炉出口主蒸气压力</div>
                  <Card>
                    <Card.Body>
                      {info && info.dailyReportGuoluData ? (
                        <Line
                          data={[
                            info.dailyReportGuoluData[4],
                            info.dailyReportGuoluData[5]
                          ]}
                          color={['#4E72C6', '#8157B0', '#4297B3']}
                          type={2}
                          unit={'(kPa)'}
                          bgColor={'78,114,198'}
                        ></Line>
                      ) : (
                        ''
                      )}
                    </Card.Body>
                  </Card>
                  <WhiteSpace size='lg' />
                  <div className={'fb backGroundTitle'}>锅炉排烟NOx浓度</div>
                  <Card>
                    <Card.Body>
                      {info && info.dailyReportGuoluData ? (
                        <Line
                          data={[
                            info.dailyReportGuoluData[6],
                            info.dailyReportGuoluData[7]
                          ]}
                          color={['#4E72C6', '#8157B0', '#4297B3']}
                          type={2}
                          unit={'(mol/L)'}
                          bgColor={'78,114,198'}
                        ></Line>
                      ) : (
                        ''
                      )}
                    </Card.Body>
                  </Card>
                </Card.Body>
              </Card>
            </div>
          </Tabs>
          <WhiteSpace size='lg' />
        </WingBlank>
      </div>
    )
  }
}

export default index
