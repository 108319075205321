/*
 * @Author: your name
 * @Date: 2021-06-22 17:16:07
 * @LastEditTime: 2021-07-06 19:14:39
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\view\layout\index.js
 */
import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import RouteList from '../../Route/List'
import _ from 'loadsh'
// const routeUrl = ''

class index extends Component {
  // firstToUpper1 (str) {
  //   return str
  //     .trim()
  //     .toLowerCase()
  //     .replace(str[0], str[0].toUpperCase())
  // }

  render () {
    let routeUrl = this.props.location.pathname
    let urlComponent = routeUrl.split('/')[1]
    let comp = 'Report'
    if (urlComponent) {
      comp = _.upperFirst(urlComponent)
    }

    return (
      <div>
        <HashRouter>
          <Switch>
            <Route path={routeUrl} component={RouteList[comp]}></Route>
          </Switch>
        </HashRouter>
      </div>
    )
  }
}

export default index
