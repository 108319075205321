/*
 * @Author: your name
 * @Date: 2021-06-29 16:05:20
 * @LastEditTime: 2021-06-29 16:49:30
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\common\IconClub.js
 */
import React, { Component } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Icon } from 'antd-mobile'

class IconClub extends Component {
  iconQue = (iconName, style) => {
    if (!iconName) {
      return ''
    }
    let preName = iconName.slice(0, 3)
    if (preName === 'fa-') {
      let lessName = iconName.slice(3)
      return (
        <i
          className={'anticon anticon-dribbble-square'}
          style={{ verticalAlign: '0em' }}
        >
          <FontAwesomeIcon style={style} icon={lessName}></FontAwesomeIcon>
        </i>
      )
    } else {
      return <Icon style={style} type={iconName} />
    }
  }
  render () {
    let { icon, style } = this.props

    return this.iconQue(icon, style)
  }
}

export default IconClub
