/*
 * @Author: your name
 * @Date: 2021-06-28 18:31:36
 * @LastEditTime: 2021-07-07 20:02:26
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\view\report\Option.js
 */
import * as echarts from 'echarts'

let lineOption = {
  title: {
    // text: '未来一周气温变化',
    // subtext: '纯属虚构'
  },
  tooltip: {
    trigger: 'axis'
  },
  legend: {
    data: []
    // top: '90%'
  },
  toolbox: {
    show: true
  },
  xAxis: {
    type: 'category',
    boundaryGap: false,
    data: []
  },
  yAxis: {
    name: '',
    type: 'value'
    // nameTextStyle: { fontWeight: '700', fontSize: '12px' }
    // axisLabel: {
    //   formatter: '{value} °C'
    // }
  },
  color: ['#4E72C6', '#8157B0', '#4297B3'],
  series: []
}

let barOption = {
  // backgroundColor: '#031d33',
  grid: {
    // top: -100,
    // bottom: -100
    // left: '10%',
    // right: '10%'
  },
  xAxis: {
    show: false,
    type: 'value',
    boundaryGap: [0, 0]
  },
  yAxis: [
    {
      type: 'category',
      data: [''],
      axisLine: { show: false },
      axisTick: [
        {
          show: false
        }
      ]
    }
  ],
  series: [
    {
      name: '金额',
      type: 'bar',
      zlevel: 1,
      itemStyle: {
        normal: {
          barBorderRadius: 30,
          color: new echarts.graphic.LinearGradient(1, 0, 0, 1, [
            {
              offset: 1,
              color: 'rgba(243, 128, 12, 1)'
            },
            {
              offset: 0,
              color: 'rgba(243, 128, 12, 0.5)'
            }
          ])
        }
      },
      barWidth: 10,
      data: [10]
    },
    {
      name: '背景',
      type: 'bar',
      barWidth: 10,
      barGap: '-100%',
      data: [20],
      itemStyle: {
        normal: {
          color: 'rgba(252, 215, 178, 0.19)',
          barBorderRadius: 30
        }
      }
    }
  ]
}

let crossBar = {
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    },
    formatter: params => {
      let result = ''
      result =
        '<div style="text-align:left;font-weight:700";margin-bottom:12px>' +
        params[0].name +
        '#机组</div><div>' +
        params[0].data +
        ' 万kW·h</div>'
      return result
    }
  },
  title: { text: '机组发电量', left: '40%', textStyle: { fontSize: '14px' } },
  grid: {
    show: false,
    top: '15%',
    right: '3%',
    left: '10%',
    bottom: '12%'
  },
  xAxis: [
    {
      type: 'category',
      data: [],
      splitNumber: 1,

      axisLine: {
        show: true,
        lineStyle: {
          color: '#8B8B8B'
        }
      },
      axisLabel: {
        // margin: 14,
        interval: 0,
        color: '#C8C8C8',
        textStyle: {
          fontSize: 12
        }
      }
    }
  ],
  yAxis: [
    {
      name: '(万kW·h)',
      type: 'value',
      axisTick: {
        show: true
      },
      axisLabel: {
        formatter: '{value}',
        color: '#C8C8C8'
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: '#8B8B8B'
        }
      },
      splitLine: {
        show: false,
        lineStyle: {
          color: 'rgba(255,255,255,0.12)'
        }
      }
    }
  ],
  series: [
    {
      type: 'bar',
      data: [40, 32, 37, 35, 54, 28, 45, 37, 31, 32, 40, 32, 37, 35, 54, 28],
      barWidth: '5',
      itemStyle: {
        normal: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1,
            [
              {
                offset: 0,
                color: 'rgba(6,62,227,0.6)' // 0% 处的颜色
              },
              {
                offset: 1,
                color: 'rgba(6,62,227,1)' // 100% 处的颜色
              }
            ],
            false
          ),
          barBorderRadius: [30, 30, 30, 30],
          shadowColor: 'rgba(0,160,221,1)',
          shadowBlur: 4
        }
      }
    }
  ]
}

export { lineOption, barOption, crossBar }
