/*
 * @Author: your name
 * @Date: 2021-06-22 14:35:15
 * @LastEditTime: 2021-07-01 14:35:54
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \dianzhan-mobile\src\index.js
 */
import React from 'react'
import ReactDOM from 'react-dom'
// import { Provider } from 'react-redux'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import axios from 'axios'
// import Tool from './common/tool'

// let tool = new Tool()
// import RequireEd from './common/require'
// const getToken = key => {
//   return localStorage.getItem(key)
// }

// console.log(RequireEd)
// let domain = { apiUrl: 'http://192.168.68.3' }
// let getTokens = getToken('userInfo')

// let require = new RequireEd(domain, getTokens)
window.get = axios.get
window.post = axios.post
window.put = axios.put
window.delete = axios.delete

ReactDOM.render(<App />, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
